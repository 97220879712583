import React from 'react';

class UserStatusBlock extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const email = this.props.user.email
    return (
      <ul className='user-status'>
        {email &&
          <React.Fragment>
            <li>{email}</li>
            <li>|</li>
            <li><a href='/users/sign_out'>Log out</a></li>
          </React.Fragment>
        }
        {!email &&
          <li><a href='/users/sign_in'>Log in</a></li>
        }
      </ul>
    )
  }
}

export default UserStatusBlock;
